import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import RightChevron from '../SVG/RightChevron'

const Card = ({ item, index }) => {
  return (
    <Link
      to={'/case-studies/' + item.node.uid}
      key={index}
      className="button-group lg:w-1/3 lg:pl-12 mb-8 lg:mb-12 flex flex-col"
    >
      {item.node.data.hero.url && (
        <div className="bg-gradient-blue">
          <Img
            className="mr-10"
            fluid={item.node.data.hero.localFile.childImageSharp.fluid}
            alt={item.node.data.hero.alt}
          />
        </div>
      )}
      <div className="bg-blue w-full text-white px-8 py-6 h-full flex flex-col justify-between">
        {item.node.data.heading && (
          <h3 className="lg:w-3/4 heading-lg mb-8">{item.node.data.heading}</h3>
        )}
        <div>
          <div className="inline-block button button--blue border">
            <div className="button__inner text-xs">
              Learn More
              <RightChevron className="ml-8" />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default Card
