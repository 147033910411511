import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import FeatureCard from '../components/Card/Feature'
import Card from '../components/Card/Default'

const CaseStudyPage = ({ data, location }) => {
  const page = data.prismicCaseStudiesIndex.data
  const mobileFeature = data.mobileFeature.data
  const posts = data.allPrismicCaseStudiesArticle.edges

  return (
    <Layout location={location}>
      <SEO
        title={page.meta_title ? page.meta_title : page.heading}
        location={location}
        description={page.meta_description && page.meta_description}
      />
      <div className="wrapper wrapper--y">
        {page.heading && (
          <h1 className="heading-2xl mb-8 lg:mb-16">{page.heading}</h1>
        )}

        <FeatureCard data={page} mobileFeature={mobileFeature} position="top" />

        <div className="lg:flex lg:flex-wrap lg:-ml-12 lg:-mb-12 pb-12 lg:pb-32">
          {posts
            .filter(item => item.node.uid !== page.featured_case_study.uid)
            .map((item, index) => {
              return <Card key={index} item={item} index={index} />
            })}
        </div>
      </div>
    </Layout>
  )
}

CaseStudyPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageHomeQuery = graphql`
  query {
    prismicCaseStudiesIndex {
      data {
        heading
        meta_title
        meta_description
        featured_case_study {
          id
          uid
          document {
            ... on PrismicCaseStudiesArticle {
              data {
                hero {
                  url
                  alt
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1250, maxHeight: 500) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                lead {
                  html
                  text
                }
                heading
              }
            }
          }
        }
        featured_case_study_text {
          html
          text
        }
      }
    }
    mobileFeature: prismicCaseStudiesIndex {
      data {
        featured_case_study {
          document {
            ... on PrismicCaseStudiesArticle {
              data {
                hero {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 640, maxHeight: 480) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicCaseStudiesArticle(
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            hero {
              url
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 694, maxHeight: 568) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            lead {
              html
              text
            }
            heading
          }
        }
      }
    }
  }
`

export default CaseStudyPage
